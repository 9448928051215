import React from 'react';

import { Tag } from '../components/Tag';
import Layout from '../components/Layout';
import SEO from '../components/Seo';

const Template = ({ pageContext }) => (
  <Layout>
    <SEO title={pageContext.slug} />
    <Tag tag={pageContext.slug} />
  </Layout>
);

export default Template;

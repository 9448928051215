import { Link, graphql, useStaticQuery } from 'gatsby';
import capitalize from 'lodash/capitalize';
import React from 'react';

import { BaseTags } from './TagsComponent';

function fetchMatchedPages(tag, drops = [], posts = []) {
  const dropsMatched = drops.reduce((prev, drop) => {
    if (drop.frontmatter.tags.includes(tag)) {
      const page = {
        title: drop.frontmatter.title,
        slug: `/drops/${drop.frontmatter.slug}`,
        tags: drop.frontmatter.tags,
      };
      prev.push(page);
    }
    return prev;
  }, []);

  const postsMatched = posts.reduce((prev, post) => {
    if (post.tags.includes(tag)) {
      const page = {
        title: post.title,
        slug: `/blog/${post.slug}`,
        tags: post.tags,
      };
      prev.push(page);
    }
    return prev;
  }, []);

  return [...dropsMatched, ...postsMatched];
}

const TagCard = ({ tags, slug, title }) => {
  return (
    <div className="py-4 group flex flex-col max-w-lg sm:m-0 rounded-md justify-around bg-white">
      <div className="h-10 flex items-center justify-between">
        <Link to={slug}>
          <h3 className="group-hover:underline group-hover:text-primary-link">
            {title}
          </h3>
        </Link>
      </div>
      <div className="h-10 flex items-center justify-between">
        <BaseTags
          className="flex text-sm"
          wrapperClass="pr-2"
          tagClass="p-2"
          tags={tags}
        />
      </div>
    </div>
  );
};

const TagPage = ({ drops, posts, tag }) => {
  const pages = fetchMatchedPages(tag, drops, posts);

  return (
    <div className="pt-10 flex flex-col">
      <h1 className="text-4xl">{capitalize(tag)} related content</h1>
      <div className="py-4 flex flex-col">
        {pages.map(page => (
          <TagCard
            key={page.title}
            title={page.title}
            slug={page.slug}
            tags={page.tags}
          />
        ))}
      </div>
      <div className="hover:text-primary-link hover:underline">
        <Link to="/">
          ← Back to home
        </Link>
      </div>
    </div>
  );
};

const QUERY = graphql`
  query {
    allMarkdownRemark {
      nodes {
        frontmatter {
          title
          slug
          tags
        }
      }
    }
    allDevArticles {
      edges {
        node {
          article {
            title
            tags
            slug
          }
        }
      }
    }
  }
`;

const Tag = ({ tag }) => {
  const data = useStaticQuery(QUERY);
  const drops = data.allMarkdownRemark.nodes;
  const posts = data.allDevArticles.edges.map(edge => edge.node.article);

  return <TagPage drops={drops} posts={posts} tag={tag} />;
};

export { Tag };
